import EgoForm from '@egodesign/form'

export const toggleButton = () => {
    let active = false
    const animationTime = 300

    const toggleButton = document.querySelector('#toggler')
    const secondaryNav = document.querySelector('#secondary-nav')
    const bodyElement = document.querySelector('body')

    if (toggleButton && secondaryNav) {
        toggleButton.addEventListener('click', () => {
            if (!active) {
                toggleButton.classList.add('active')
                secondaryNav.classList.add('open')
                bodyElement.classList.add('no-scroll')
            } else {
                toggleButton.classList.remove('active')
                secondaryNav.classList.remove('open')
                setTimeout(() => {
                    bodyElement.classList.remove('no-scroll')
                }, animationTime)
            }
            active = !active
        })
    }
}

export const categoriesDesktop = () => {
    const equipment = document.querySelector('.header__nav .equipment')
    const content = document.querySelector('.header__nav .show-content')
    const container = document.querySelector('.header__nav .show-content__container')
    const items = document.querySelectorAll('.header__nav .show-content__item')
    let activeItem = null
    if (equipment && content && container) {
        equipment.addEventListener('click', (e) => {
            e.preventDefault()
            content.style.opacity = '1'
            container.style.maxHeight = '80vh'
            content.style.display = 'flex'
        })
    }
    if (container && content) {
        container.addEventListener('mouseleave', () => {
            content.style.opacity = '0'
            setTimeout(() => {
                content.style.display = 'none'
                container.style.maxHeight = '0'
            }, 250)
        })
    }
    if (items) {
        items.forEach((item) => {
            item.addEventListener('click', () => {
                if (item !== activeItem) {
                    if (activeItem) {
                        activeItem.classList.remove('--active')
                    }
                    item.classList.add('--active')
                    activeItem = item
                    const centerContent = document.querySelectorAll('.show-content__center > div')
                    const contentKey = item.getAttribute('data-content')
                    for (const content of centerContent) {
                        if (content.dataset.content === contentKey) {
                            content.style.opacity = '1'
                            setTimeout(() => {
                                content.style.display = 'block'
                            }, 250)
                        } else {
                            content.style.opacity = '0'
                            setTimeout(() => {
                                content.style.display = 'none'
                            }, 250)
                        }
                    }
                } else {
                    item.classList.remove('--active')
                    activeItem = null
                    const centerContent = document.querySelectorAll('.show-content__center > div')
                    for (const content of centerContent) {
                        content.style.opacity = '0'
                        setTimeout(() => {
                            content.style.display = 'none'
                        }, 250)
                    }
                }
                const hasActive = [...items].some((item) => item.classList.contains('--active'))
                const rightContent = document.querySelector('.show-content__right')
                if (hasActive) {
                    setTimeout(() => {
                        rightContent.style.opacity = '1'
                    }, 250)
                } else {
                    rightContent.style.opacity = '0'
                }
            })
        })
    }
}

export const categoriesMobile = () => {
    const categories = document.querySelectorAll('.header__secondary-nav .accordion-dropdown-item__details .show-content__item')

    if (categories) {
        categories.forEach((category) => {
            category.addEventListener('click', () => {
                const subcategory = category.nextElementSibling

                if (subcategory.style.display === 'flex') {
                    subcategory.style.display = 'none'
                } else {
                    const openSubcategories = document.querySelectorAll('.header__secondary-nav .accordion-dropdown-item__details .show-content__subcategory')

                    openSubcategories.forEach((openSubcategory) => {
                        openSubcategory.style.display = 'none'
                    })

                    subcategory.style.display = 'flex'
                }
            })
        })
    }
}

export const searchBarHome = () => {
    const headerSearchElement = document.querySelector('#headerSearchForm')
    if (!headerSearchElement) return

    // Desktop
    let searchBarIsOpen = false
    const inputContainer = headerSearchElement.querySelector('.header-search-bar__input-container')
    const searchBarContainer = document.querySelector('.header-search-bar')
    const closeIcon = headerSearchElement.querySelector('.header-search-bar__input-close')
    const btnSubmit = headerSearchElement.querySelector('button[type="submit"]')
    const searchInput = headerSearchElement.querySelector('.header-search-bar__input')
    const cleanValueIcon = headerSearchElement.querySelector('.header-search-bar__clean-value')
    const headerNav = document.querySelector('.header__nav')

    inputContainer?.addEventListener('click', function () {
        if (!searchBarIsOpen) {
            headerNav.style.overflow = 'hidden'
            searchBarContainer.classList.add('--open')
            searchBarIsOpen = true
        }
    })

    closeIcon?.addEventListener('click', function () {
        if (searchBarIsOpen) {
            searchBarContainer.classList.remove('--open')
            searchBarIsOpen = false
            searchInput.value = ''
            btnSubmit.classList.add('--disabled')
            cleanValueIcon.classList.remove('--show')
            setTimeout(() => {
                headerNav.style.overflow = 'unset'
            }, 500)
        }
    })

    searchInput?.addEventListener('input', function () {
        if (searchInput.value.trim().length > 1) {
            btnSubmit.classList.remove('--disabled')
            cleanValueIcon.classList.add('--show')
        } else {
            btnSubmit.classList.add('--disabled')
            cleanValueIcon.classList.remove('--show')
        }
    })

    cleanValueIcon?.addEventListener('click', function () {
        searchInput.value = ''
        btnSubmit.classList.add('--disabled')
        cleanValueIcon.classList.remove('--show')
    })

    const headerSearchElementMobile = document.querySelector('#headerSearchFormMobile')
    const inputSearchTermMobile = headerSearchElementMobile.querySelector('#inputSearch')
    const btnSubmitMobile = headerSearchElementMobile.querySelector('button[type="submit"]')

    if (headerSearchElement) {
        const headerSearchForm = new EgoForm({
            element: headerSearchElement,
            submitType: 'GET',
            submitDataForm: 'formData',
        })
    }

    if (headerSearchElementMobile) {
        const headerSearchFormMobile = new EgoForm({
            element: headerSearchElementMobile,
            submitType: 'GET',
            submitDataForm: 'formData',
        })
    }

    if (inputSearchTermMobile) {
        inputSearchTermMobile.addEventListener('input', () => {
            if (inputSearchTermMobile.value.trim().length > 2) {
                btnSubmitMobile.classList.remove('--disabled')
            } else {
                btnSubmitMobile.classList.add('--disabled')
            }
        })
    }
}

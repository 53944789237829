import { findProduct } from '../assets/js/modules/tools'
import { createStore } from 'zustand/vanilla'
import { persist, createJSONStorage } from 'zustand/middleware'
import { _products } from '../mock/dummy-data'
import { sparePartAddToCartGTM } from '../assets/js/modules/marketing/gtm'

const fake_api_call = new Promise((resolve, reject) => {
    setTimeout(() => resolve(_products), 1000)
})

const store = createStore(
    persist(
        (set, get) => ({
            products: [],
            cart: [],
            getProducts: async () => {
                const products = []

                document.querySelectorAll('.card-product')?.forEach((item) => {
                    products.push({
                        id: item.dataset.product,
                        name: item.dataset.name,
                        code: item.dataset.code,
                        img: item.dataset.img,
                        brand: item.dataset.brand,
                        category: item.dataset.category,
                        description: item.dataset.description,
                        landingpage: item.dataset.landingpage,
                        quantity: 0,
                    })
                })

                const uniqueProducts = products.reduce((uniqueArray, currentItem) => {
                    const index = uniqueArray.findIndex((item) => item.id === currentItem.id)
                    if (index === -1) {
                        uniqueArray.push(currentItem)
                    }
                    return uniqueArray
                }, [])

                // Set the new products in the state
                set({ products: uniqueProducts })

                // Mark products in cart
                get().cart.forEach((item) => {
                    document.querySelectorAll(`[data-product="${item.id}"]`).forEach((el) => {
                        el.classList.add('--selected')
                        const el_qt = el.querySelector('.product_quantity')
                        if (el_qt) el_qt.value = item.quantity
                    })
                })

                // Return the products to get the in the js
                return get().products
            },

            addToCart: (product_id) => {
                const products = get().products

                const existingCartItem = get().cart.find((item) => item.id === product_id)

                if (existingCartItem) {
                    const updatedCart = get().cart.map((item) => {
                        if (item.id === product_id) {
                            return { ...item, quantity: item.quantity + 1 }
                        }
                        return item
                    })

                    set({ cart: updatedCart })
                } else {
                    const item = findProduct(products, product_id)
                    set({
                        cart: [
                            ...get().cart,
                            {
                                ...item,
                                quantity: 1,
                            },
                        ],
                    })

                    const itemsSelected = document.querySelectorAll(`.cards-with-filter [data-product="${product_id}"]`)

                    if (itemsSelected.length) {
                        itemsSelected.forEach((item) => {
                            item.classList.add('--selected')
                        })
                    }

                    sparePartAddToCartGTM(item)
                }
            },
            removeFromCart: (product_id) => {
                const updatedCart = get().cart.filter((item) => {
                    return item.id !== product_id
                })

                set({ cart: updatedCart })

                const itemsToRemove = document.querySelectorAll(`.cards-with-filter [data-product="${product_id}"]`)
                if (itemsToRemove.length) {
                    itemsToRemove.forEach((item) => {
                        item.classList.remove('--selected')
                    })
                }
            },
            removeOneItemFromCart: (product_id) => {
                const existingCartItem = get().cart.find((item) => item.id === product_id)

                if (existingCartItem && existingCartItem.quantity > 1) {
                    const updatedCart = get().cart.map((item) => {
                        if (item.id === product_id) {
                            return { ...item, quantity: item.quantity - 1 }
                        }
                        return item
                    })

                    set({ cart: updatedCart })
                } else {
                    return
                }
            },
            getCartProducts: () => get().cart,
            getCartProductById: (product_id) => {
                return get().cart.find((item) => item.id === product_id)
            },
            getProductById: (product_id) => {
                return get().products.find((item) => item.id === product_id)
            },
            isProductInCart: (product_id) => {
                return get().cart.some((item) => item.id === product_id)
            },
            removeAllItemsFromCart: () => {
                set({ cart: [] })
                document.querySelectorAll('.card-product.--selected')?.forEach((el) => el.classList.remove('--selected'))
            },
        }),
        {
            name: 'sullair-products',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
)

export default store

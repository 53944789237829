export const fixedBreadcrumb = () => {
    const propFilter = document.querySelector('.properties-filter') // Evitar que sea fixed si existe en la página el componente para filtrar propiedades
    if (propFilter) return
    const breadcrumb = document.querySelector('#main-breadcrumb')
    const mainHeader = document.querySelector('.main-header')
    if (!(breadcrumb && mainHeader)) return
    const formContact = document.querySelector('.form-contact')
    // const body = document.querySelector('body')

    // // SHOW/HIDE FIXED
    // window.onscroll = function () {
    //     handleScroll()
    // }
    // let curr = document.documentElement.scrollTop
    // const handleScroll = () => {
    //     if (document.documentElement.scrollTop > 0) {
    //         if (curr < document.documentElement.scrollTop && 52 <= curr) {
    //             if (!body.classList.contains('--form-intersection')) body.classList.add('--fixed-breadcrump-active')
    //         } else {
    //             body.classList.remove('--fixed-breadcrump-active')
    //         }
    //     } else {
    //         body.classList.remove('--fixed-breadcrump-active')
    //     }

    //     // Si el bottom de topBar es mayor o igual al top de formContact, hay intersección
    //     if (formContact) {
    //         const formContactRect = formContact.getBoundingClientRect()
    //         if (65 >= formContactRect.top) {
    //             body.classList.remove('--fixed-breadcrump-active')
    //             body.classList.add('--form-intersection')
    //         } else {
    //             body.classList.remove('--form-intersection')
    //         }
    //     }

    //     curr = document.documentElement.scrollTop
    // }

    // HANDLE CLICK
    const handleOnClick = () => {
        if (formContact) {
            formContact.scrollIntoView({ behavior: 'smooth' })
        } else {
            window.location.href = '/contacto'
        }
    }
    const topBarBtn = document.querySelector('.breadcrumb-nav__cta')
    topBarBtn.addEventListener('click', handleOnClick)
}

// ENVIA EVENTOS A LA API DE FACEBOOK/META

const baseURL = `${process.env.BASE_URL}/marketing/meta`
const csrf = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

export const metaLead = async (userData) => {
    const data = {
        email: userData['Email'],
        name: userData['FirstName'],
        surname: userData['LastName'],
    }

    try {
        const response = await fetch(`${baseURL}/lead/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-CSRFToken': csrf,
            },
        })
        if (!response.ok) {
            throw new Error('Error al enviar el lead en API de Meta')
        }
    } catch (error) {
        console.error(`Error: ${error}`)
    }
}

const cta = async (label) => {
    const data = { label }

    try {
        const response = await fetch(`${baseURL}/cta/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-CSRFToken': csrf,
            },
        })
        if (!response.ok) {
            throw new Error('Error al enviar cta en API de Meta')
        }
    } catch (error) {
        console.error(`Error: ${error}`)
    }
}

export const metaCta = () => {
    const ctaTriggers = document.querySelectorAll('[data-gtm-action="cta"]') // Mismo información para GTM
    if (!ctaTriggers) return
    ctaTriggers?.forEach((ctaTrigger) => {
        ctaTrigger.addEventListener('click', () => {
            cta(ctaTrigger.dataset.gtmLabel)
        })
    })
}

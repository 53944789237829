export const footerSocialMedia = () => {
    const footerIcon = document.querySelectorAll(".footer__social-link");

    if (footerIcon) {
        footerIcon.forEach((icon) => {
            const firstIcon = icon.getAttribute("aria-label");
            
            if (firstIcon === "Facebook" || firstIcon === "LinkedIn") {
                icon.firstChild.style.fill = "#fff";
            } else if (firstIcon === "Instagram") {
                const svg = icon.firstChild;
                const path = svg.firstChild.nextSibling
                svg.firstChild.style.fill = "#fff";
                svg.lastChild.style.color = "#2d2d2d";
                path.style.fill = "#2d2d2d";
                path.style.color = "#2d2d2d";
                svg.style.width = "22px";
                svg.style.height = "22px";
            } else if (firstIcon === "Youtube") {
                const svg = icon.firstChild;
                svg.firstChild.style.fill = "#fff";
                svg.lastChild.style.color = "#2d2d2d";
                svg.lastChild.style.fill = "#2d2d2d";
            }
        })
    }
}
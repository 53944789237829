export const _products = [
    {
        id: "id1",
        name: "Producto 1",
        code: "code1",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: true,
        is_featured: true,
    },
    {
        id: "id2",
        name: "Producto 2",
        code: "code2",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: false,
        is_featured: true,
    },
    {
        id: "id3",
        name: "Producto 3",
        code: "code3",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: false,
        is_featured: true,
    },
    {
        id: "id4",
        name: "Producto 4",
        code: "code4",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: false,
        is_featured: true,
    },
    {
        id: "id5",
        name: "Producto 5",
        code: "code5",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: false,
        is_featured: true,
    },
    {
        id: "id6",
        name: "Producto 6",
        code: "code6",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: true,
        is_featured: true,
    },
    {
        id: "id1",
        name: "Producto 1",
        code: "code1",
        img: "/static/website/images/categories/webinar.png",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, consequuntur.", // opcional, no todas tienen
        is_sullebe: true,
        is_featured: true,
    },
    // EJEMPLO
    // La pagina de productos genericos es una spare parts sin el buscador
    // Este se va a tener que destildar en el back
    // En las landings de sullube faltan 2 componentes:
    // 1) El buscador con un chooser a un brand. Ej: Sullube. Y al linkearlo me devuelve todos los productos para esa brand
    // 2) Las cards img + title + description + button

    // INTERFACE PRODUCTO
    // {
    //     id: number,
    //     name: string,
    //     code: string,
    //     category: object,
    //     image: string | object, // si le hago .url me devuelve la url de la imagen
    //     brand: object, // ver si es lo mismo que page
    //     page: object, // tengo el atributo .full_url que me trae la url de la pagina. Me va a indicar a la pagina que yo tengo que redireccionar. Solo tienen pages asociadas las que son de alguna marca o algun generico
    // },
];
